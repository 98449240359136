import React, { Component } from 'react';
import './css/style.module.css';
import User from './User'
import ReservationModal from './ReservationModal'
import { FewSlotsThreshold, RegularSlotsThreshold, ManySlotsThreshold, ReserveScreenMessage } from '../config/config'

export class Reservation extends Component {
    static displayName = Reservation.name;

    constructor(props) {
        super(props);
        this.state = {
            schedule: [],
            timetable: [],
            loading: true,
            showAlert: false,
            currentDate: new Date(),
            progress: { "GinoStep1Count": 0, "GinoStep2Count": 0 }
        };
    }

    componentDidMount() {
        this.populateScheduleData(new Date());
    }

    childToParent = () => {
        window.location.reload(false);
    }

    printTime(slotno, datestring) {
        var dateinfo = new Date(datestring);
        return slotno + ' ' + dateinfo.getHours() + ':' + dateinfo.getMinutes().toString().padStart(2, '0') + '～';
    }

    printTimeTableData(slotno, datestring, isvisible) {
        if (isvisible) {
            return (<th>{this.printTime(slotno, datestring)}</th>);
        }
        else {
            return ("");
        }
    }

    printDate(datestring, isholiday) {
        var dateinfo = new Date(datestring);
        var datedisp = (dateinfo.getMonth() + 1) + '/' + dateinfo.getDate();
        if (dateinfo.getDay() === 0) {
            datedisp = datedisp + '(日)';
        }
        else if (dateinfo.getDay() === 1) {
            datedisp = datedisp + '(月)';
        }
        else if (dateinfo.getDay() === 2) {
            datedisp = datedisp + '(火)';
        }
        else if (dateinfo.getDay() === 3) {
            datedisp = datedisp + '(水)';
        }
        else if (dateinfo.getDay() === 4) {
            datedisp = datedisp + '(木)';
        }
        else if (dateinfo.getDay() === 5) {
            datedisp = datedisp + '(金)';
        }
        else if (dateinfo.getDay() === 6) {
            datedisp = datedisp + '(土)';
        }

        return datedisp;
    }

    printAvailability(slotcount, slot, dateTime) {
        var userNumber = User.getNumber();

        if (slotcount >= ManySlotsThreshold) {
            return <ReservationModal userNumber={userNumber} slot={slot} reservationdate={dateTime} display="◎" iscancel="false" mode="btn-primary" childToParent={this.childToParent} />;
        }
        else if (slotcount >= RegularSlotsThreshold) {
            return <ReservationModal userNumber={userNumber} slot={slot} reservationdate={dateTime} display="○" iscancel="false" mode="btn-primary" childToParent={this.childToParent} />;
        }
        else if (slotcount >= FewSlotsThreshold) {
            return <ReservationModal userNumber={userNumber} slot={slot} reservationdate={dateTime} display="△" iscancel="false" mode="btn-primary" childToParent={this.childToParent} />;
        }
        else if (slotcount < 0) {
            return <ReservationModal userNumber={userNumber} slot={slot} reservationdate={dateTime} display="予約" iscancel="true" mode="btn-warning" childToParent={this.childToParent} />;
        }
        else {
            return '―';
        }
    }

    printTableData(slotcount, slot, dateTime, isdisplay) {
        if (isdisplay) {
            return (<td class="align-middle">{this.printAvailability(slotcount, slot, dateTime)}</td>);
        }
        else {
            return ("");
        }
    }

    printHoliday(isHoliday) {
        if (isHoliday) {
            return (<font color="red">㊡</font>);
        }
        else {
            return '';
        }
    }

    renderCalendarTableDay(datedisp, scheduleDay) {

        return (<tr align="center">
            <th scope="row">{datedisp}{this.printHoliday(scheduleDay.isHoliday)}</th>
            {this.printTableData(scheduleDay.remainingSlot1,  1,  datedisp, scheduleDay.isSlot1Visible)}
            {this.printTableData(scheduleDay.remainingSlot2,  2,  datedisp, true)}
            {this.printTableData(scheduleDay.remainingSlot3,  3,  datedisp, true)}
            {this.printTableData(scheduleDay.remainingSlot4,  4,  datedisp, true)}
            {this.printTableData(scheduleDay.remainingSlot5,  5,  datedisp, true)}
            {this.printTableData(scheduleDay.remainingSlot6,  6,  datedisp, true)}
            {this.printTableData(scheduleDay.remainingSlot7,  7,  datedisp, true)}
            {this.printTableData(scheduleDay.remainingSlot8,  8,  datedisp, true)}
            {this.printTableData(scheduleDay.remainingSlot9,  9,  datedisp, true)}
            {this.printTableData(scheduleDay.remainingSlot10, 10, datedisp, scheduleDay.isSlot10Visible)}
            {this.printTableData(scheduleDay.remainingSlot11, 11, datedisp, scheduleDay.maxVisibleSlot >= 11)}
            {this.printTableData(scheduleDay.remainingSlot12, 12, datedisp, scheduleDay.maxVisibleSlot >= 12)}
            {this.printTableData(scheduleDay.remainingSlot13, 13, datedisp, scheduleDay.maxVisibleSlot >= 13)}
            {this.printTableData(scheduleDay.remainingSlot14, 14, datedisp, scheduleDay.maxVisibleSlot >= 14)}
        </tr>);
    }

    renderCalendarTable(schedule, timetable) {
        var datedisp1 = this.printDate(schedule.schedule1.scheduleDate, schedule.schedule1.isHoliday);
        var datedisp2 = this.printDate(schedule.schedule2.scheduleDate, schedule.schedule2.isHoliday);
        var datedisp3 = this.printDate(schedule.schedule3.scheduleDate, schedule.schedule3.isHoliday);
        var datedisp4 = this.printDate(schedule.schedule4.scheduleDate, schedule.schedule4.isHoliday);
        var datedisp5 = this.printDate(schedule.schedule5.scheduleDate, schedule.schedule5.isHoliday);
        var datedisp6 = this.printDate(schedule.schedule6.scheduleDate, schedule.schedule6.isHoliday);
        var datedisp7 = this.printDate(schedule.schedule7.scheduleDate, schedule.schedule7.isHoliday);

        return (
            <div class="table-responsive text-nowrap">
                <table class="table">
                    <thead>
                        <tr align="center" class="table-dark">
                            <th>日付</th>
                            {this.printTimeTableData('①', timetable.slot1, schedule.schedule1.isSlot1Visible)}
                            {this.printTimeTableData('②', timetable.slot2, true)}
                            {this.printTimeTableData('③', timetable.slot3, true)}
                            {this.printTimeTableData('④', timetable.slot4, true)}
                            {this.printTimeTableData('⑤', timetable.slot5, true)}
                            {this.printTimeTableData('⑥', timetable.slot6, true)}
                            {this.printTimeTableData('⑦', timetable.slot7, true)}
                            {this.printTimeTableData('⑧', timetable.slot8, true)}
                            {this.printTimeTableData('⑨', timetable.slot9, true)}
                            {this.printTimeTableData('⑩', timetable.slot10, schedule.schedule1.isSlot10Visible)}
                            {this.printTimeTableData('⑪', timetable.slot11, schedule.schedule1.maxVisibleSlot >= 11)}
                            {this.printTimeTableData('⑫', timetable.slot12, schedule.schedule1.maxVisibleSlot >= 12)}
                            {this.printTimeTableData('⑬', timetable.slot13, schedule.schedule1.maxVisibleSlot >= 13)}
                            {this.printTimeTableData('⑭', timetable.slot14, schedule.schedule1.maxVisibleSlot >= 14)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderCalendarTableDay(datedisp1, schedule.schedule1)}
                        {this.renderCalendarTableDay(datedisp2, schedule.schedule2)}
                        {this.renderCalendarTableDay(datedisp3, schedule.schedule3)}
                        {this.renderCalendarTableDay(datedisp4, schedule.schedule4)}
                        {this.renderCalendarTableDay(datedisp5, schedule.schedule5)}
                        {this.renderCalendarTableDay(datedisp6, schedule.schedule6)}
                        {this.renderCalendarTableDay(datedisp7, schedule.schedule7)}
                    </tbody>
                </table>
            </div>);
    }

    static renderPrevButton(obj, basedatestring) {
        let handlePrevClick = () => {
            var date = new Date(basedatestring);
            var prevDate = new Date(date.getFullYear(), date.getMonth());
            prevDate.setDate(date.getDate() - 7);
            obj.populateScheduleData(prevDate);
        };

        var date = new Date(basedatestring);
        date.setMilliseconds(0);
        date.setSeconds(0);

        var today = new Date();
        today.setMilliseconds(0);
        today.setSeconds(0);
        var date1 = date.getTime();
        var today1 = today.getTime();
        if (today1 <= date1) {
            return (<button className="btn btn-info" onClick={handlePrevClick}>前の週</button>);
        }
        return (<button className="btn btn-dark">前の週</button>);
    }

    static renderNextButton(obj, basedatestring) {
        let handleNextClick = () => {
            var date = new Date(basedatestring);
            var nextDate = new Date(date.getFullYear(), date.getMonth());
            nextDate.setDate(date.getDate() + 7);
            obj.populateScheduleData(nextDate);
        };

        return (
            <button className="btn btn-info" onClick={handleNextClick}>次の週</button>
        );
    }

    static renderKyoshu(displayNo, steplist, reservelist) {
        if (steplist && steplist.includes(displayNo)) {
            return (<button className="btn btn-primary btn-sm">{displayNo}</button>);
        }
        if (reservelist && reservelist.includes(displayNo)) {
            return (<button className="btn btn-warning btn-sm">{displayNo}</button>);
        }
        else {
            return (<button className="btn btn-light btn-sm">{displayNo}</button>);
        }
    }

    static renderKyoshuSet(stepcount, steplist, reservelist) {
        const rows = [];
        for (var i = 0; i < stepcount; i++) {
            rows.push(Reservation.renderKyoshu(i+1, steplist, reservelist));
        }
        return rows;
    }

    static renderProgress(progress) {
        return (
        <div>
            <h4>技能教習進捗</h4>
            <h6>１段階</h6>
            <div class="btn-group">
                {Reservation.renderKyoshuSet(progress.ginoStep1Count, progress.ginoStep1List, progress.ginoStep1ReserveList)}
            </div>
            <p />
            <h6>２段階</h6>
            <div class="btn-group">
                {Reservation.renderKyoshuSet(progress.ginoStep2Count, progress.ginoStep2List, progress.ginoStep2ReserveList)}
            </div>

            <p />
            <h4>学科教習進捗</h4>
            <h6>１段階</h6>
                <div class="btn-group">
                    {Reservation.renderKyoshu(1, progress.gakkaStep1List)}
                    {Reservation.renderKyoshu(2, progress.gakkaStep1List)}
                    {Reservation.renderKyoshu(3, progress.gakkaStep1List)}
                    {Reservation.renderKyoshu(4, progress.gakkaStep1List)}
                    {Reservation.renderKyoshu(5, progress.gakkaStep1List)}
                    {Reservation.renderKyoshu(6, progress.gakkaStep1List)}
                    {Reservation.renderKyoshu(7, progress.gakkaStep1List)}
                    {Reservation.renderKyoshu(8, progress.gakkaStep1List)}
                    {Reservation.renderKyoshu(9, progress.gakkaStep1List)}
                    {Reservation.renderKyoshu(10, progress.gakkaStep1List)}
            </div>
            <p />
            <h6>２段階</h6>
            <div class="btn-group">
                    {Reservation.renderKyoshu(1, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(2, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(3, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(4, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(5, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(6, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(7, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(8, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(9, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(10, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(11, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(12, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(13, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(14, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(15, progress.gakkaStep2List)}
                    {Reservation.renderKyoshu(16, progress.gakkaStep2List)}
            </div>
            <p />
            <button className="btn btn-primary btn-sm">済は青色</button>&nbsp;
            <button className="btn btn-warning btn-sm">予約は黄色</button>
        </div>);
    }

    render() {
        let contents = this.state.loading
            ? <p><em>読み込み中...</em></p>
            : this.renderCalendarTable(this.state.schedule, this.state.timetable);

        let progressContents = this.state.loading
            ? <p><em>読み込み中...</em></p>
            : Reservation.renderProgress(this.state.progress);

        let nameandnumber = this.state.loading ? "" : <h6>{this.state.progress.name}さん、ようこそ！(番号:{User.getNumber()})</h6>

        let prevbutton = Reservation.renderPrevButton(this, this.state.currentDate.toISOString());
        let nextbutton = Reservation.renderNextButton(this, this.state.currentDate.toISOString());

        return (
            <div>
                <table width="100%"><tr><td align="right">{nameandnumber}</td></tr></table> 
                <h3 id="tableLabel">予約情報</h3>
                {prevbutton}&nbsp;
                {nextbutton}
                <p />
                <table width="100%"><tr><td align="right">{ReserveScreenMessage}</td></tr></table> 
                {contents}
                <p />
                {progressContents}
            </div>
        );
    }

    async populateScheduleData(datetime) {
        var date = new Date(datetime.toDateString());
        //alert("pop " + date);
        const response = await fetch('schedule/listschedule?dateTime=' + date.toISOString() + '&userNumber=' + User.getNumber());
        const data = await response.json();
        const timetableresponse = await fetch('/schedule/timetable');
        const timetable = await timetableresponse.json();

        const userprogressresponse = await fetch('/user/getuserprogress?userNumber=' + User.getNumber());
        const userprogress = await userprogressresponse.json();
        this.setState({ schedule: data, timetable: timetable, loading: false, currentDate: datetime, progress: userprogress });
    }
}
