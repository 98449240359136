import { Bus } from "./components/Bus";
import { Reservation } from "./components/Reservation";
import { Instructor } from "./components/Instructor";
import { Home } from "./components/Home";
import { Logout } from "./components/Logout";
import { Navigate } from "react-router-dom";

const AppRoutes = [
{
    index: true,
    element: <Home />
},
{
    path: '/logout',
    element: <Logout />
},
{
    path: '/instructor',
    element: <Instructor />
},
{
    path: '/reservation',
    element: <Reservation />
},
{
    path: '/bus',
    element: <Bus />
},
{
    path: '*',
    element: <Navigate to="/login" replace />
}
];

export default AppRoutes;
