import React, { Component } from 'react';
import { Container } from 'reactstrap';
import {
    Route,
    Routes,
    Outlet,
    Navigate,
} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { LoginWithNavigate } from './components/Login';
import { NavMenu } from './components/NavMenu'
import User from './components/User'
import './custom.css';

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
        };
    }

    static displayName = App.name;

    render() {
        this.setState({ loggedIn: User.isLoggedIn() });

        return (
            <div>
                <NavMenu />
                <Container tag="main">
                    <Routes>
                        <Route path='/login' element={<LoginWithNavigate />} />
                        <Route element={<PrivateOutlet />}>
                            {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                            })}
                        </Route>
                    </Routes>
                </Container>
            </div>
        );
    }
}

function PrivateOutlet() {
    const auth = User.isLoggedIn();
    return auth ? <Outlet /> : <Navigate to="/login" />;
}

