import React, { Component } from 'react';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { DisplayInstructorPictures, CanChangeInstructorPreference, DisplayYusenInstructors } from '../config/config'
import User from './User'

export class Instructor extends Component {
    static displayName = Instructor.name;

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { favorites: [], denied: [], others: [], loading: true, collapse: 1 };
    }

    toggle(e) {
        let event = e.target.dataset.event;
        this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) })
    }

    componentDidMount() {
        this.populateInstructorData();
    }

    static renderPicture(instructor) {
        if (DisplayInstructorPictures > 0) {
            return (<img class="rounded-circle float-start" src={instructor.picturePath} alt="prefer" width="100" height="100" />);
        }
        return (<span />);
    }

    static renderYusenInstructors(favorites, collapse) {
        if (DisplayYusenInstructors > 0) {
            return (<Card style={{ marginBottom: '1rem' }} key="1">
                <CardHeader onClick={this.toggle} data-event="1">優先指導員</CardHeader>
                <Collapse isOpen={collapse === 1}>
                    <CardBody>
                        <ul class="list-group">
                            {favorites.map(instructor => {
                                return (
                                    <div class="list-group-item">
                                        {Instructor.renderPicture(instructor)}
                                        <h5>{instructor.name}</h5>
                                        {Instructor.renderYusenCommand(instructor)}
                                    </div>
                                );
                            })}
                        </ul>
                    </CardBody>
                </Collapse>
            </Card>);
        }
        return (<span />);
    }

    static renderYusenCommand(instructor) {
        if (CanChangeInstructorPreference) {
            return (<span><button class="btn btn-danger">苦手指定</button>&nbsp;<button class="btn btn-warning">優先解除</button></span>);
        }
        return (<span />);
    }

    static renderNigateCommand(instructor) {
        if (CanChangeInstructorPreference) {
            return (<span><button class="btn btn-danger">優先指定</button> & nbsp; <button class="btn btn-warning">苦手解除</button></span>);
        }
        return (<span />);
    }

    static renderOtherCommand(instructor) {
        if (CanChangeInstructorPreference) {
            return (<span><button class="btn btn-success">優先指定</button>&nbsp;<button class="btn btn-danger">苦手指定</button></span>);
        }
        return (<span />);
    }

    render() {
        const { collapse, favorites, denied, others } = this.state;
        if (this.state.loading) {
            return (<p><em>読み込み中...</em></p>);
        }
        return (
            <div className="container">
                <h3 className="page-header">指導員設定</h3>
                {Instructor.renderYusenInstructors(favorites, collapse)}
                <Card style={{ marginBottom: '1rem' }} key="2">
                    <CardHeader onClick={this.toggle} data-event="2">苦手指導員</CardHeader>
                    <Collapse isOpen={collapse === 2}>
                        <CardBody>
                            <ul class="list-group">
                                {denied.map(instructor => {
                                    return (
                                        <div class="list-group-item">
                                            {Instructor.renderPicture(instructor)}
                                            <h5>{instructor.name}</h5>
                                            {Instructor.renderNigateCommand(instructor)}
                                        </div>
                                    );
                                })}
                            </ul>
                        </CardBody>
                    </Collapse>
                </Card>
                <Card style={{ marginBottom: '1rem' }} key="3">
                    <CardHeader onClick={this.toggle} data-event="3">指定なし指導員</CardHeader>
                    <Collapse isOpen={collapse === 3}>
                        <CardBody>
                            <ul class="list-group">
                                {others.map(instructor => {
                                    return (
                                        <div class="list-group-item">
                                            {Instructor.renderPicture(instructor)}
                                            <h5>{instructor.name}</h5>
                                            {Instructor.renderOtherCommand(instructor)}
                                        </div>
                                    );
                                })}
                            </ul>
                        </CardBody>
                    </Collapse>
                </Card>
            </div>
        );
    }

    async populateInstructorData(datetime) {
        const fresponse = await fetch('/instructorlist/favoriteinstructors?userNumber=' + User.getNumber());
        const favoritelist = await fresponse.json();
        const dresponse = await fetch('/instructorlist/deniedinstructors?userNumber=' + User.getNumber());
        const deniedlist = await dresponse.json();
        const oresponse = await fetch('/instructorlist/otherinstructors?userNumber=' + User.getNumber());
        const otherlist = await oresponse.json();

        this.setState({ favorites: favoritelist, denied: deniedlist, others: otherlist, loading: false });
    }
}
