import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, NavbarText } from 'reactstrap';
import { Link } from 'react-router-dom';
import User from './User'
import './NavMenu.css';
import { DSName, DisplayBusReservation, UnderMaintenance } from '../config/config';


export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor (props) {
        super(props);

        this.intervalId = 0;
        this.pathname = window.location.pathname;

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.logoutWarning = this.logoutWarning.bind(this);
        this.logoutCountdown = this.logoutCountdown.bind(this);
        this.logoutByTimeout = this.logoutByTimeout.bind(this);
        this.state = {
            collapsed: true,
            logoutWarning: false,
            logoutCounter: 59,
        };
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    logoutWarning() {
        clearInterval(this.intervalId);
        // １分後ログアウト
        this.setState({
            logoutWarning: true,
        });
        this.intervalId = setInterval(this.logoutCountdown, 1000);
    }

    logoutCountdown() {
        console.log("counter:" + this.state.logoutCounter);
        if (this.state.logoutCounter === 1) {
            clearInterval(this.props.intervalId);
            // ログアウト処理へ
            this.intervalId = setInterval(this.logoutByTimeout, 1000);
            return;
        }
        clearInterval(this.intervalId);
        this.intervalId = setInterval(this.logoutCountdown, 1000);
        this.setState({
            logoutCounter: this.state.logoutCounter - 1,
        });
    }

    logoutByTimeout() {
        window.location.href = "/logout";
    }

    componentDidUpdate(prevProps) {
        // URLが変更された
        if (this.pathname !== window.location.pathname) {
            console.log("old:"+this.pathname);
            console.log("new:" + window.location.pathname);
            this.pathname = window.location.pathname;

            if (User.isLoggedIn()) {
                // ２分後に警告
                clearInterval(this.intervalId);
                this.intervalId = setInterval(this.logoutWarning, 120000);
                this.setState({
                    logoutWarning: false,
                    logoutCounter: 59
                });
            }
        }
    }

    renderBus() {
        var userNumber = User.getNumber();
        if (UnderMaintenance === 0 && DisplayBusReservation && userNumber === '242070') {
            return (
                <NavItem>
                    <NavLink tag={Link} className="inactive" activeClassName="active" to="/bus">バス予約</NavLink>
                </NavItem>);
        }
    }

    renderKyoshu() {
        if (UnderMaintenance === 0) {
            return (
                <NavItem>
                    <NavLink tag={Link} className="inactive" activeClassName="active" to="/reservation">教習予約</NavLink>
                </NavItem>);
        }
    }

    renderMenu() {
        if (User.isLoggedIn()) {
            return (
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={Link} className="inactive" activeClassName="active" to="/">マイページ</NavLink>
                        </NavItem>
                        {this.renderKyoshu()}
                        {this.renderBus()}
                        <NavItem>
                            <NavLink tag={Link} className="inactive" activeClassName="active" to="/logout">ログアウト</NavLink>
                        </NavItem>
                    </ul>
                </Collapse>);
        }
    }

    renderWarning() {
        if (User.isLoggedIn() && this.state.logoutWarning) {
            return (<NavbarText>ログアウトまで{this.state.logoutCounter}秒</NavbarText>);
        }
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/">{DSName}</NavbarBrand>
                    {this.renderWarning()}
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    {this.renderMenu()}
                </Navbar>
            </header>
        );
    }
}
