import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';

export default class ReservationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    toggleModal = () => {
        this.setState((prevState) => ({
            modalOpen: !prevState.modalOpen,
        }));
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        // Handle form submission logic here (e.g., send data to server)

        let formData = new FormData();
        formData.append("userNumber", this.props.userNumber);
        formData.append("reserveDate", this.props.reservationdate);
        formData.append("timeslot", this.props.slot);

        if (this.props.iscancel == "false") {
            //alert("reserve " + formData.get("userNumber") + " " + formData.get("reserveDate") + " " + formData.get("timeslot"));
            await fetch("reservation/reserve", {
                method: "POST",
                body: formData
            }).then(async response => await response.json())
                .then(data => console.log(data));
        }
        else {
            if (formData.get("reserveDate") === this.printDate(new Date())) {
                alert("ネット予約システムでは当日キャンセルができません。教習所に電話連絡をしてください");
            }
            else {
                await fetch("reservation/cancel", {
                    method: "POST",
                    body: formData
                }).then(async response => await response.json())
                    .then(data => console.log(data));
            }
        }

        // do a page refresh
        this.props.childToParent();

        // Close the modal
        this.toggleModal();
    };

    submitButton(buttonMessage, datetime) {
        //alert("date " + datetime);
        return (<input className="btn btn-primary"
            type="submit"
            value={buttonMessage}
            onClick={this.handleSubmit} />);
    }

    printDate(dateinfo) {
        var datedisp = (dateinfo.getMonth() + 1) + '/' + dateinfo.getDate();
        if (dateinfo.getDay() === 0) {
            datedisp = datedisp + '(日)';
        }
        else if (dateinfo.getDay() === 1) {
            datedisp = datedisp + '(月)';
        }
        else if (dateinfo.getDay() === 2) {
            datedisp = datedisp + '(火)';
        }
        else if (dateinfo.getDay() === 3) {
            datedisp = datedisp + '(水)';
        }
        else if (dateinfo.getDay() === 4) {
            datedisp = datedisp + '(木)';
        }
        else if (dateinfo.getDay() === 5) {
            datedisp = datedisp + '(金)';
        }
        else if (dateinfo.getDay() === 6) {
            datedisp = datedisp + '(土)';
        }
        return datedisp;
    }

    render() {
        var message = "";
        var buttonMessage = "";
        if (this.props.iscancel == "false") {
            message = "に教習を予約しますか？";
            buttonMessage = "予約実行";
        }
        else {
            message = "に予約が入っています。(当日キャンセルは電話で学校に連絡してください)";
            buttonMessage = "予約キャンセル";
        }

        return (
            <div>
                <Button className={this.props.mode} onClick={this.toggleModal}>
                    {this.props.display}
                </Button>
                <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>教習予約</ModalHeader>
                    <ModalBody>
                        {this.props.slot}時限目（{this.props.reservationdate}）{message}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>
                            戻る
                        </Button>
                        {' '}
                        <form>
                            <input type="hidden" name="userNumber" value={this.props.userNumber} />
                            <input type="hidden" name="reserveDate" value={this.props.dateTime} />
                            <input type="hidden" name="timeslot" value={this.props.slot} />
                            {this.submitButton(buttonMessage, this.props.dateTime)}
                        </form>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
