import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Alert, Button } from 'reactstrap'
import User from './User'
import { useParams, useNavigate } from 'react-router-dom'
import { StudentDisplay } from '../config/config';

export const withNavigate = Component => props => {
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} params={params} navigate={navigate} />;
}

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            password: '',
            errMessage: ''
        };
    }

    click = async () => {
        try {
            var loggedin = await User.login(this.state.id, this.state.password);
            if (loggedin) {
                this.setState({ errMessage: '' });
                this.props.navigate('/');
            }
            else {
                this.setState({ errMessage: StudentDisplay + '番号かパスワードが違います' });
                this.props.navigate('/login');
            }
        } catch (e) {
            this.setState({ errMessage: 'システムエラーです。管理者に連絡してください' });
            // '教習者番号かパスワードが違います'
            this.props.navigate('/login');
        }
    };

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        var message = StudentDisplay + "を入力してください";
        return (
            <Form>
                {this.state.errMessage && (
                    <Alert variant="danger">{this.state.errMessage}</Alert>
                )}
                <FormGroup controlId="id">
                    <Label>{StudentDisplay}番号</Label>
                    <Input type="text" name="id" id="id" value={this.state.id} onChange={this.handleChange} placeholder={message} />
                </FormGroup>
                <FormGroup controlId="password">
                    <Label>パスワード</Label>
                    <Input type="password" name="password" id="password" value={this.state.password} onChange={this.handleChange} placeholder="パスワードを入力してください" />
                </FormGroup>
                <Button variant="primary" type="button" onClick={this.click}>ログイン</Button>
            </Form>
        );
    }
}

export const LoginWithNavigate = withNavigate(Login);