import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import User from './User';

export class Logout extends Component {
    async componentDidMount() {
        await User.logout();
    }

    render() {
        return (
            <div>
                <h2>ログアウトしました</h2>
                <div className="text-center">
                    <Link to="/login">ログイン画面へ</Link>
                </div>
            </div>
        );
    }
}