import React, { Component } from 'react';
import { UncontrolledCollapse, Button, ButtonGroup, FormGroup, Label, Input } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import Route from './Route';
import User from './User'
import { BusReservationList } from './BusReservationList';
import './css/Bus.css';

export class Bus extends Component {

    constructor(props) {
        super(props);

        const currentHour = moment().hour();
        const defaultDate = this.getDefaultDate();

        this.state = {
            routes: [],
            holidays: [],
            loading: true,
            selectedDate: defaultDate,
            focused: false,
            isToSchool: true,
            errorMessage: '', // State for error messages
            reservations: [],
            expandedRouteIndex: null,
        };
    }

    getDefaultDate = () => {
        const currentHour = moment().hour();
        return currentHour < 12 ? moment().add(1, 'days') : moment().add(2, 'days');
    }

    validateDate = (date) => {
        const { holidays, reservations } = this.state;

        console.log("Validating date: ", date.format("YYYY-MM-DD"));

        // Log the holidays for debugging
        console.log("Holidays: ", holidays.map(holiday => moment(holiday).format("YYYY-MM-DD")));

        // Check if the selected date is a holiday
        const isHoliday = holidays.some(holiday => moment(holiday).isSame(date, 'day'));

        // Log the result of the holiday check
        console.log("Is holiday: ", isHoliday);

        if (isHoliday) {
            this.setState({ errorMessage: "休日には予約できません" });
            return false;
        }

        // Filter reservations for the selected date
        const dayReservations = reservations.filter(reservation =>
            moment(reservation.departureDate).isSame(date, 'day')
        );

        // Update the state based on the reservations found for the selected date
        if (dayReservations.length > 0) {
            const hasToSchool = dayReservations.some(r => !r.isReturn); // Not return trips
            const hasFromSchool = dayReservations.some(r => r.isReturn); // Return trips

            if (hasToSchool && hasFromSchool) {
                this.setState({ errorMessage: "この日は既に予約があります" });
            } else if (hasToSchool) {
                this.setState({ errorMessage: "帰り便のみ予約できます", isToSchool: false });
            } else if (hasFromSchool) {
                this.setState({ errorMessage: "行き便のみ予約できます", isToSchool: true });
            } else {
                this.setState({ errorMessage: '' }); // No error
            }
        } else {
            this.setState({ errorMessage: '' }); // No reservations for this date
        }

        return true;
    }

    componentDidMount() {
        this.populateBusData().then(() => {
            // Call validateDate with the default date after data is loaded
            this.validateDate(this.state.selectedDate);
        });
    }

    childToParent = () => {
        this.populateBusData().then(() => {
            // Call validateDate with the default date after data is loaded
            this.validateDate(this.state.selectedDate);
        });
    }

    handleDateChange = (date) => {
        if (date && !date.isSame(this.state.selectedDate, 'day')) {
            this.setState({ selectedDate: date }, () => {
                // Validate the new date selection
                this.validateDate(date);  // Pass date to validate
            });
        }
    }


    // Add the refreshReservations method
    refreshReservations = async () => {
        try {
            const userNumber = User.getNumber();
            const response = await fetch(`busreservation/getbusreservations?userId=${userNumber}`);
            const data = await response.json();
            this.setState({ reservations: data });
        } catch (error) {
            console.error('Failed to fetch bus reservation data', error);
        }
    }

    renderRoutes(routes, holidays) {
        var rsvdate = this.state.selectedDate.toDate();
        const { errorMessage, reservations } = this.state; // Include reservations in the destructuring

        const isError = !!errorMessage; // Check if there is an error message

        // Determine if buttons should be disabled
        const disableToSchool = errorMessage === "帰り便のみ予約できます" || errorMessage === "この日は既に予約があります" || errorMessage === "休日には予約できません";
        const disableFromSchool = errorMessage === "行き便のみ予約できます" || errorMessage === "この日は既に予約があります" || errorMessage === "休日には予約できません";

        return (
            <div>
                <BusReservationList
                    reservations={reservations}
                    refreshReservations={this.refreshReservations} // Pass refresh function
                    onCancelComplete={async () => {
                        await this.refreshReservations(); // Refresh the list of reservations
                        this.validateDate(this.state.selectedDate); // Revalidate the selected date
                    }}
                />
                <h4>予約日</h4>
                <SingleDatePicker
                    date={this.state.selectedDate}
                    onDateChange={this.handleDateChange} // Call the date change handler
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    numberOfMonths={1}
                    isOutsideRange={(day) => {
                        const minDate = moment().add(1, 'days');
                        return day.isBefore(minDate) || day.isAfter(moment().add(7, 'days'));
                    }}
                    displayFormat="MM月DD日"
                />
                {/* Display the error message if it exists */}
                {errorMessage && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                        {errorMessage}
                    </div>
                )}
                <p />
                <h4>行きか帰りを選んでください</h4>
                <ButtonGroup>
                    <Button
                        color={this.state.isToSchool ? "primary" : "secondary"}
                        onClick={() => this.setState({ isToSchool: true })}
                        disabled={disableToSchool} // Disable if error message indicates only return trips
                    >
                        行き便
                    </Button>
                    <Button
                        color={!this.state.isToSchool ? "primary" : "secondary"}
                        onClick={() => this.setState({ isToSchool: false })}
                        disabled={disableFromSchool} // Disable if error message indicates only to trips
                    >
                        帰り便
                    </Button>
                </ButtonGroup>
                <p />
                <h4>バスルート情報</h4>
                <div className="d-flex flex-column">
                    {routes.length === 0 ? (
                        <div>停留所情報が登録されていません。</div>
                    ) : (
                        routes.map((item, index) => this.renderRoute(item, index, rsvdate))
                    )}
                </div>
            </div>
        );
    }

    renderRoute(route, index, rsvdate) {
        const togglerId = `toggler-${index}`;
        const { errorMessage, expandedRouteIndex } = this.state;

        // Determine if the route buttons should be disabled
        const disableRoute = errorMessage === "休日には予約できません" || errorMessage === "この日は既に予約があります";

        if (disableRoute && expandedRouteIndex !== null) {
            this.setState({
                expandedRouteIndex: null
            });
        }

        return (
            <div key={index}>
                <Button
                    color="info"
                    id={togglerId}
                    className="mb-4"
                    disabled={disableRoute}
                    onClick={() => {
                        // If the button is disabled, do nothing
                        if (disableRoute) return;

                        // Toggle the current route; collapse if it's already expanded
                        this.setState(prevState => ({
                            expandedRouteIndex: prevState.expandedRouteIndex === index ? null : index
                        }));
                    }}
                >
                    {route.routeName}
                </Button>
                <UncontrolledCollapse toggler={`#${togglerId}`} isOpen={expandedRouteIndex === index}>
                    <Route routeId={route.routeId} isToSchool={this.state.isToSchool} rsvdate={rsvdate} childToParent={this.childToParent} />
                </UncontrolledCollapse>
            </div>
        );
    }

    render() {
        const { loading, routes, holidays } = this.state;
        const contents = loading
            ? <p><em>読み込み中...</em></p>
            : this.renderRoutes(routes, holidays);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateBusData() {
        try {
            const userNumber = User.getNumber();
            const [routeRes, holidayRes, reservationRes] = await Promise.all([
                fetch(`busreservation/getmasterroutes?userId=${userNumber}`),
                fetch(`/busreservation/getnextweekholidays?startDate=${new Date().toISOString()}`),
                fetch(`busreservation/getbusreservations?userId=${userNumber}`)
            ]);

            if (!routeRes.ok || !holidayRes.ok || !reservationRes.ok) {
                throw new Error("データの取得に失敗しました。");
            }

            const routes = await routeRes.json();
            const holidays = await holidayRes.json();
            const reservations = await reservationRes.json();

            this.setState({ routes, holidays, reservations, loading: false }, () => {
                // Call validateDate after state is set
                this.validateDate(this.state.selectedDate);
            });
        } catch (error) {
            console.error('Failed to fetch bus data', error);
            this.setState({ loading: false, errorMessage: 'データの取得に失敗しました。もう一度お試しください。' });
        }
    }
}
