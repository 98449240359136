import React, { Component } from 'react';
import User from './User';
import { Table, Button } from 'reactstrap';

export class BusReservationList extends Component {
    renderBusReservations(reservations) {
        return (
            <div>
                <h4>バス予約情報</h4>
                <Table striped>
                    <thead>
                        <tr>
                            <th>種別</th>
                            <th>日付</th>
                            <th>路線</th>
                            <th>停留所</th>
                            <th>便</th>
                            <th>時間</th>
                            <th>変更された時間</th>
                            <th>予約メモ</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {reservations.map((item, index) => this.renderReservation(item, index))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderReservation(reservation, index) {
        var departureDate = new Date(reservation.departureDate).toLocaleDateString();
        var enrouteDisplay = reservation.isReturn ? "帰り" : "行き";
        return (
            <tr key={index}>
                <td>{enrouteDisplay}</td>
                <td>{departureDate}</td>
                <td>{reservation.routeName}</td>
                <td>{reservation.stopName}</td>
                <td>{reservation.sequence}</td>
                <td>{reservation.departureTime}</td>
                <td>{reservation.changeTimeDisplay}</td>
                <td>{reservation.memo}</td>
                <td>
                    <Button color="danger" onClick={() => this.handleCancel(reservation.stopId, reservation.departureDate, reservation.sequence)}>
                        キャンセル
                    </Button>
                </td>
            </tr>
        );
    }

    render() {
        const { reservations } = this.props; // Get reservations from props

        return (
            <div>
                {this.renderBusReservations(reservations)}
            </div>
        );
    }

    async handleCancel(stopId, rsvdate, sequence) {
        const confirmCancel = window.confirm('この予約をキャンセルしてもよろしいですか？'); // Confirmation prompt
        if (!confirmCancel) return;

        try {
            var userNumber = User.getNumber();
            let formData = new FormData();
            formData.append("personNum", userNumber);
            formData.append("stopId", stopId);
            formData.append("rsvdate", rsvdate);
            formData.append("sequence", sequence);

            await fetch("busreservation/cancelbusride", {
                method: "POST",
                body: formData
            });

            this.props.refreshReservations(); // Refresh reservations after cancellation

            if (this.props.onCancelComplete) {
                this.props.onCancelComplete(); // Trigger the date validation in Bus.js
            }

        } catch (error) {
            console.error('Error occurred during cancellation', error);
        }
    }
}

