class User {
    isLoggedIn = () => this.get('isLoggedIn') === 'true';

    getNumber = () => this.get('id');

    set = (key, value) => localStorage.setItem(key, value);

    get = key => this.getLocalStorage(key);

    getLocalStorage = key => {
        const ret = localStorage.getItem(key);
        if (ret) {
            return ret;
        }
        return null;
    };

    login = async (id, password) => {
        // ログイン処理
        // ログインエラー時には、falseを返してもいいし、returnを別の用途で利用したかったら
        // 例外を出しして呼び出し元でcatchしてもいいかと思います。

        var formData = new FormData();
        formData.append('id', id);
        formData.append('password', password);
        const aresponse = await fetch('/user/login', {method: 'POST', mode: 'cors', body: formData});

        if (aresponse.status === 200) {
            const authtoken = await aresponse.json();
            if (authtoken) {
                this.set('id', id);
                this.set('isLoggedIn', true);
                return true;
            }
        }

        this.set('id', null);
        this.set('isLoggedIn', false);
        return false;
    };

    logout = async () => {
        if (this.isLoggedIn()) {
            this.set('isLoggedIn', false);

            // ログアウト処理
            //　他に必要な処理があるのならこちら
        }
    };
}

export default new User();